<template>
  <div class="detail-table t-order-detail pa-0 pa-sm-4 rounded-lg">
    <v-row
      no-gutters
      v-if="!$vuetify.breakpoint.smAndDown"
      class="table-header"
      align="center"
    >
      <v-col cols="6" sm="8" class="pl-0">
        {{ $t("orders.orderDetail.header.product") }}
      </v-col>
      <v-col cols="4" sm="4" class="d-flex justify-center text-center">
        {{ $t("orders.orderDetail.header.qtyOrdered") }}<br />
        {{ $t("orders.orderDetail.header.qtyPrepared") }}
      </v-col>
    </v-row>

    <v-list class="t-detail-list">
      <v-list-item
        v-for="item in items"
        v-bind:key="item.itemId"
        :to="
          item.product.productInfos.PRODUCT_NATURE_ID !== '8' &&
          item.product.productInfos.PRODUCT_NATURE_ID !== '10'
            ? {
                name: 'Product',
                params: { productSlug: item.product.slug }
              }
            : ''
        "
        class="order-item"
        :class="[item.deliveredType]"
      >
        <!-- prodotto -->
        <div
          class="replacedLabelRow d-flex justify-center align-center"
          v-if="item.deliveredType == 'replaced'"
        >
          <span class="label primary lighten-1">
            <v-icon small color="white">
              mdi-arrow-down
            </v-icon>
            <span
              class="white--text primary lighten-1 text-uppercase font-weight-bold text-caption ml-1"
              v-html="$t('orders.list.replacedWith')"
            />
          </span>
        </div>
        <v-row no-gutters align="center">
          <v-col cols="12" sm="8" class="d-flex align-center mb-3 mb-sm-0">
            <img
              :src="item.product.mediaURL"
              class="product-img"
              width="85px"
              height="85px"
              max-height="85px"
              max-width="85px"
            />
            <div class="product-info">
              <span class="product-name">{{ item.product.name }}</span>
              <span class="product-brand">{{ item.product.shortDescr }}</span>
              <div class="product-descr">{{ item.product.description }}</div>
              <div class="product-item-info">
                {{ getItemOption(item) }}
              </div>
            </div>
          </v-col>
          <!-- prezzo unitario -->
          <!-- <v-col
            cols="4"
            sm="4"
            md="2"
            class="d-flex flex-column justify-center product-info"
          >
            <span
              class="order-info-title price-title hidden-md-and-up"
              v-html="$t('orders.orderDetail.header.priceUnit')"
            >
            </span>
            <span class="unit-price text-center">
              {{ item.unitPrice | currency }}</span
            >
          </v-col> -->
          <!-- quantità ordinata e quantità preparata -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex flex-column justify-end align-center product-info text-center"
          >
            <span class="order-info-title hidden-md-and-up">
              {{ $t("orders.orderDetail.header.qtyOrdered") }}/<br />
              {{ $t("orders.orderDetail.header.qtyPrepared") }}
            </span>
            <div class="d-flex flex-row flex-md-column align-center">
              <OrderDetailQuantity :item="item" :delivered="false" />
              <span class="d-flex d-md-none">/</span>
              <OrderDetailQuantity :item="item" :delivered="true" />
            </div>
          </v-col>
          <!-- prezzo totale -->
          <!-- <v-col
            cols="3"
            sm="4"
            md="2"
            class="d-flex flex-column justify-center product-info text-center"
          >
            <span
              class="order-info-title hidden-md-and-up"
              v-html="$t('orders.orderDetail.header.total')"
            >
            </span>
            <strong class="gross-total">
              {{
                $n(
                  delivered ? item.deliveredGrossTotal : item.grossTotal,
                  "currency"
                )
              }}
            </strong>
          </v-col> -->
          <v-col
            cols="12"
            class="text-left"
            v-if="isMasterAccount && item.orderItemInfos.user_notes"
          >
            <strong>Note:</strong> {{ item.orderItemInfos.user_notes }}
          </v-col>
          <v-col cols="12" v-if="getReplacementAccepted(item)">
            <div class="text-body-2 font-weight-bold mt-3 mx-2 ml-sm-8 ml-md-3">
              {{ $t("orders.list.replacementAccepted") }}
            </div>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
// import ProductPrice from "@/components/product/ProductPrice.vue";
import OrderDetailQuantity from "@/components/orders/OrderDetailQuantity.vue";
import { mapGetters } from "vuex";

export default {
  name: "OrderDetailTable",
  props: {
    items: { required: true, type: [] },
    order: { required: true },
    delivered: { required: false, default: false }
  },
  components: { OrderDetailQuantity },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isMasterAccount: "cart/isMasterAccount"
    })
  },
  methods: {
    getItemOption(item) {
      return item.orderItemInfos?.size;
    },
    getReplacementAccepted(item) {
      return (
        !this.order.warehouse?.metaData?.warehouse_info?.HIDE_SUBSTITUTE &&
        item.orderItemInfos?.accept_alternatives === "true"
      );
    }
  }
};
</script>
